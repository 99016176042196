import { PUBLIC_REQUEST_KEY, API_PATH } from "../../constants/common.constant"
import fetch_ from "../../interceptors/FetchInterceptor"

const {
    GET_ESSAY,
    USERS_LIST,
    USERS_COUNT,
    BUSINESS_USERS_COUNT,
    USERS_REGISTER,
    BUSINESS_USER_REGISTER,
    BUSINESS_USERS_EDIT,
    BUSINESS_USERS_LIST_FILTER,
    USERS_UPDATE,
    USERS_DETAIL,
    USERS_DELETE,
    USERS_FORM_FIELDS,
    BUSINESS_USERS_LIST,
    ORDERS_LIST, ORDERS_COUNT
} = API_PATH;

export const UserService_ = {
    getEssay: (filter) => {
        return fetch_({
            url: GET_ESSAY,
            method: 'POST',
            data: filter,
            headers: {
                [PUBLIC_REQUEST_KEY]: true
            },
        })
    },

    getUsers: (filter) => {
        return fetch_({
            url: USERS_LIST,
            method: 'GET',
            params: filter
        })
    },
    getBusinessUsersWithFilter: (filter) => {
        return fetch_({
            url: BUSINESS_USERS_LIST_FILTER,
            method: 'GET',
            params: filter
        })
    },

    getBusinessUsers: () => {
        return fetch_({
            url: BUSINESS_USERS_LIST,
            method: 'GET',
        })
    },

    getFormfields: (filter) => {
        return fetch_({
            url: USERS_FORM_FIELDS,
            method: 'GET',
            params: filter
        })
    },
    countUsers: (filter) => {
        return fetch_({
            url: USERS_COUNT,
            method: 'GET',
            params: filter
        })
    },
    countBusinessUsers: (filter) => {
        return fetch_({
            url: BUSINESS_USERS_COUNT,
            method: 'GET',
            params: filter
        })
    },
    getUser: userId => {
        return fetch_({
            url: USERS_DETAIL(userId),
            method: 'GET',
        })
    },
    addUser: user => {
        return fetch_({
            url: USERS_REGISTER,
            method: 'POST',
            data: user
        })
    },

    addBusinessUser: user => {
        return fetch_({
            url: BUSINESS_USER_REGISTER,
            method: 'POST',
            data: user
        })
    },
    updateUser: (userId, user) => {
        return fetch_({
            url: USERS_UPDATE(userId),
            method: 'PUT',
            data: user
        })
    },
    updateBusinessUser: (userId, user) => {
        return fetch_({
            url: BUSINESS_USERS_EDIT(userId),
            method: 'PUT',
            data: user
        })
    },
    deleteUser: (userId) => {
        return fetch_({
            url: USERS_DELETE(userId),
            method: 'DELETE'
        })
    },
    getOrders: (filter) => {
        return fetch_({
            url: ORDERS_LIST,
            method: 'GET',
            params: filter
        })
    },
    countOrders: (filter) => {
        return fetch_({
            url: ORDERS_COUNT,
            method: 'GET',
            params: filter
        })
    }
}
