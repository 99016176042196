import axios from 'axios'
import { API_BASE_URL, IS_TENANT, TENANT, TENANT_ID, B2B_BASE_URL } from 'configs/AppConfig'
import history from '../history'
import { notification } from 'antd';
import { PUBLIC_REQUEST_KEY, ENTRY_ROUTE, TOKEN_PAYLOAD_KEY, AUTH_TOKEN_KEY, X_TENANT_ID, API_PATH, B2B_REQUEST_KEY } from '../constants/common.constant'
import { GetLocalStorage, RemoveLocalStorage } from '../utils/common';

const fetch_ = axios.create({
    baseURL: `${API_BASE_URL}api/v1`,
    timeout: 60000
})

// API Request interceptor
fetch_.interceptors.request.use(async config => {
    const jwtToken = GetLocalStorage(AUTH_TOKEN_KEY)

    if (jwtToken) {
        config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
    }

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
        history.push(ENTRY_ROUTE);
        window.location.reload();
    }

    if (config.headers[B2B_REQUEST_KEY]) {
        config.baseURL = `${B2B_BASE_URL}api/v1`
    }

    return config
}, error => {
    // Do something with request error here
    notification.error({
        message: 'Error'
    })
    Promise.reject(error)
})

// API respone interceptor
fetch_.interceptors.response.use((response) => {

    const data = response.data;
    if (data.message) {
        notification.success({ description: data.message })
    }
    return data.data;

}, (error) => {
    const { data = {}, status, statusText } = error?.response || {};
    data.description = data.message || statusText;
    data.message = data.error || statusText;
    data.statusCode = data.statusCode || status;

    if (
        [401, 403].includes(data.statusCode) &&
        ![
            API_BASE_URL.LOGIN,
            API_BASE_URL.SEND_OTP,
        ].includes(error.response?.config?.url)
    ) {
        // RemoveLocalStorage(AUTH_TOKEN_KEY)
        // history.push(ENTRY_ROUTE);
        // window.location.reload();
    }

    // notification.error(data?.message?.message || "Something went wrong")

    return Promise.reject(data);
});

export default fetch_