import { Action, Subjects } from "casl/subjects";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import { ROLE_TYPE } from "constants/common.constant";
import { currentUserRole } from "utils/common";

export const NAV_PATH = {
  login: `${AUTH_PREFIX_PATH}/login`,
  dashboard: `${APP_PREFIX_PATH}/dashboard`,
  business_users: `${APP_PREFIX_PATH}/business_users`,
  active_users: `${APP_PREFIX_PATH}/active_users`,
  cancel_order: `${APP_PREFIX_PATH}/cancel_order`,
  orders: `${APP_PREFIX_PATH}/orders`,
  users: `${APP_PREFIX_PATH}/users`,
  adminUsers: `${APP_PREFIX_PATH}/admin_users`,
  scholarships: {
    list: `${APP_PREFIX_PATH}/scholarships`,
    detail: (scholarshipId) =>
      `${APP_PREFIX_PATH}/scholarships/detail/${scholarshipId}`,
    root: `${APP_PREFIX_PATH}/scholarships`,
    wizard: `${APP_PREFIX_PATH}/scholarships/wizard`,
    template: `${APP_PREFIX_PATH}/scholarships/wizard/template`,
    applicant_detail: (id) =>
      `${APP_PREFIX_PATH}/scholarships/applicant_detail/${id}`,
    winners_list: (id) => `${APP_PREFIX_PATH}/scholarships/winners_list/${id}`,
    rank_criteria: (id) =>
      `${APP_PREFIX_PATH}/scholarships/rank_criteria/${id}`,
    edit: (id) => `${APP_PREFIX_PATH}/scholarships/edit/${id}`,
    targetAudience: (id) =>
      `${APP_PREFIX_PATH}/scholarships/wizard/${id}/target_audience`,
    dataCapturing: (id) =>
      `${APP_PREFIX_PATH}/scholarships/wizard/${id}/capturing`,
    deadline: (id) => `${APP_PREFIX_PATH}/scholarships/wizard/${id}/deadline`,
    attributes: (id) =>
      `${APP_PREFIX_PATH}/scholarships/wizard/${id}/attributes`,
    budget: (id) => `${APP_PREFIX_PATH}/scholarships/wizard/${id}/budget`,
    requirements: (id) =>
      `${APP_PREFIX_PATH}/scholarships/wizard/${id}/requirements`,
    escrow: (id) => `${APP_PREFIX_PATH}/scholarships/wizard/${id}/escrow`,
    publish: (id) => `${APP_PREFIX_PATH}/scholarships/wizard/${id}/publish`,
  }
};

/*Supper User menu start*/
const superUserNav = [
  {
    key: "Dashboard",
    path: `${NAV_PATH.business_users}`,
    title: "Business Users",
    submenu: [],
  },
  {
    key: "Users",
    path: `${NAV_PATH.users}`,
    title: "Users",
    submenu: [],
  },
  {
    key: "AdminUsers",
    path: `${NAV_PATH.adminUsers}`,
    title: "Admin Users",
    submenu: [],
  },
  {
    key: "Scholarships",
    path: `${NAV_PATH.scholarships.list}`,
    title: "Scholarship",
    submenu: [],
  },
  {
    key: "Orders",
    path: `${NAV_PATH.orders}`,
    title: "Orders",
    submenu: [],
  },
  // {
  //   key: "activeUsers",
  //   path: `${NAV_PATH.active_users}`,
  //   title: "Subscribed Users",
  //   submenu: [],
  // },
  // {
  //   key: "cancelOrder",
  //   path: `${NAV_PATH.cancel_order}`,
  //   title: "Cancelled Orders",
  //   submenu: [],
  // },
];

const navigationConfig = [
  ...superUserNav,
];

export const navigationConfigMenu = () => {
  const navigationConfig = [];
  navigationConfig.push(...superUserNav);
  return navigationConfig;
};

export default navigationConfig;
