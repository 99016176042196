import { AUTH_TOKEN_KEY, USER_ACCESS_KEY, USER_INFO_KEY } from 'constants/common.constant';
import { RemoveLocalStorage, SetLocalStorage } from 'utils/common';
import {
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
} from '../constants/Auth';

export const signIn = ({ user, accessToken, access }) => {
  SetLocalStorage(AUTH_TOKEN_KEY, accessToken);
  SetLocalStorage(USER_INFO_KEY, user);
  SetLocalStorage(USER_ACCESS_KEY, access);
  return {
    type: AUTHENTICATED,
    token: accessToken,
    userInfo: user,
    userAccess: access,
  }
};

export const authenticated = (token) => {
  return {
    type: AUTHENTICATED,
    token
  }
};

export const signOut = () => {
  RemoveLocalStorage(AUTH_TOKEN_KEY)
  RemoveLocalStorage(USER_INFO_KEY)
  RemoveLocalStorage(USER_ACCESS_KEY)
  RemoveLocalStorage("formFields")
  return {
    type: SIGNOUT
  };
};

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  }
};

export const signUp = (user) => {
  return {
    type: SIGNUP,
    payload: user
  };
};

export const signUpSuccess = (token) => {
  return {
    type: SIGNUP_SUCCESS,
    token
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message
  };
};

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  };
};

export const showLoading = (loading = true) => {
  return {
    type: SHOW_LOADING,
    data: loading
  };
};
