const hostname = window.location.hostname;
const hostnameParts = hostname.split(".");
const NODE_ENV = process.env.NODE_ENV;
// const origin = 'http://52.3.145.163/';

 const origin = 'https://api.lenny.ai/';

// const origin = 'https://api-scholarship.lenlen.ai/';
//const origin = 'http://localhost:3000/';

export const tenant = NODE_ENV === 'development' ? '' : (hostnameParts.length === 3 ? hostnameParts[0] : null);
// export const tenant = null;
export const isTenant = !!tenant;

localStorage.setItem("isTenant", isTenant);
localStorage.setItem("tenant", tenant);
export const tenantId = (tenantId) => {
	if (tenantId) {
		localStorage.setItem("tenantId", tenantId);
	}
	return localStorage.getItem("tenantId");
}


const dev = {
	APP_NAME: 'Application',
	API_ENDPOINT_URL: origin,
	B2B_API_ENDPOINT_URL: origin,
	SUPER_ADMIN_API_ENDPOINT_URL: origin,
	TENANT_API_ENDPOINT_URL: origin,
	FRONTEND_DOMAIN: 'localhost',
};

const prod = {
	APP_NAME: 'Application',
	API_ENDPOINT_URL: origin,
	B2B_API_ENDPOINT_URL: origin,
	SUPER_ADMIN_API_ENDPOINT_URL: origin,
	TENANT_API_ENDPOINT_URL: origin,
	FRONTEND_DOMAIN: 'localhost',
};

const getEnv = () => {
	switch (NODE_ENV) {
		case 'production':
			return prod
		default:
			return dev
	}
}

export const env = getEnv()
