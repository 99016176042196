import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { NAV_PATH } from "configs/NavigationConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${NAV_PATH.scholarships.template}`} exact component={lazy(() => import(`./scholarships/create/scholarship-wizard`))} />
        <Route path={`${NAV_PATH.scholarships.wizard}`} component={lazy(() => import(`./scholarships/create/scholarship-wizard`))} />
        <Route path={`${APP_PREFIX_PATH}/scholarshipdetail/:id`} exact component={lazy(() => import(`./scholarships/create/scholarship-wizard`))} />
        <Route path={`${NAV_PATH.dashboard}`} exact component={lazy(() => import(`./dashboards`))} />
        <Route path={`${NAV_PATH.users}`} exact component={lazy(() => import(`./users`))} />
        <Route path={`${NAV_PATH.adminUsers}`} exact component={lazy(() => import(`./admin-users`))} />
        <Route path={`${NAV_PATH.business_users}`} exact component={lazy(() => import(`./business-users`))} />
        <Route path={`${NAV_PATH.active_users}`} exact component={lazy(() => import(`./active-users`))} />
        <Route path={`${NAV_PATH.cancel_order}`} exact component={lazy(() => import(`./cancel-order`))} />
        <Route path={`${NAV_PATH.orders}`} exact component={lazy(() => import(`./orders`))} />
        <Route path={`${NAV_PATH.scholarships.list}`} exact component={lazy(() => import(`./scholarships`))} />
        <Route
          exact
          path={`${NAV_PATH.scholarships.detail(`:id`)}`}
          component={lazy(() => import(`./scholarships/create/screens/Detail`))}
        />
        <Route
          exact
          path={`${NAV_PATH.scholarships.edit(`:id`)}`}
          component={lazy(() => import(`./scholarships/edit/scholarships-tab`))}
        />


        {/* <Redirect from={`${APP_PREFIX_PATH}`} to={`${NAV_PATH.dashboard}`} /> */}
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);
