import { TENANT_PREFIX_PATH } from "configs/AppConfig";

export const API_BASE_URL = ''

// Config
export const ENTRY_ROUTE = '/auth/login'
export const TOKEN_PAYLOAD_KEY = 'authorization'
export const PUBLIC_REQUEST_KEY = 'public-request'
export const B2B_REQUEST_KEY = 'b2b-request'

export const AUTH_TOKEN_KEY = 'token';
export const USER_INFO_KEY = 'userInfo';
export const USER_ACCESS_KEY = 'user_access';

export const TENANT_KEY = 'tenant';
export const X_TENANT_ID = 'x-tenant-id';

export const ROLE_TYPE = {
    SUPER_ADMIN: 'SUPER_ADMIN',
    STUDENTS: 'STUDENTS',
}
export const ROLES = [
    { label: 'Super Admin', val: 'SUPER_ADMIN' },
    { label: 'Student', val: 'STUDENTS' },
];

export const SYSTEM_ROLES = [
    { label: 'Super Admin', value: 'SUPER_ADMIN' },
    { label: 'Student', value: 'STUDENTS' },
];

export const TENANTS_ROLES = [];

export const DEFAULT_PAGE_SIZE = 10;

export const ADD = 'ADD'
export const EDIT = 'EDIT'

export const UPLOAD_DRAG_PROPS = {
    name: 'file',
    multiple: true,
    listType: "picture-card",
    showUploadList: false,
    action: null
}

export const API_PATH = {
    SUPER_ADMIN_LOGIN: '/users/auth/login',
    SUPER_ADMIN_LOGOUT: '/auth/logout',
    SUPER_ADMIN_PASSWORD_LINK: '/auth/reset-password-link',
    SUPER_ADMIN_PASSWORD: '/auth/reset-password',

    GET_ESSAY: '/users/get-essay',

    //users
    USERS_LIST: "/register-applicants",
    USERS_LIST: "/register-applicants",
    USERS_COUNT: "/register-applicants/count",
    USERS_REGISTER: "/register-applicants",
    USERS_UPDATE: (userId) => `/register-applicants/${userId}`,
    USERS_DETAIL: (userId) => `/register-applicants/${userId}`,
    USERS_DELETE: (userId) => `/register-applicants/${userId}`,
    USERS_FORM_FIELDS: "register-applicants/form-fields",

    //orders
    ORDERS_LIST: "/user-payments",
    ORDERS_COUNT: "/user-payments/count",


    // Business user 
    BUSINESS_USERS_LIST: "users/business-users",
    BUSINESS_USERS_LIST_FILTER: "/users",
    BUSINESS_USERS_COUNT: "/users/count",
    BUSINESS_USER_REGISTER: "users/auth/register",
    BUSINESS_USERS_EDIT: (userId) => `users/businessUser/${userId}`,

    // register applicants 
    APPLICANTS_USERS_LIST: "/register-applicants",
    // USERS_COUNT: "/users/counts",
    // USERS_REGISTER: "/users",
    // USERS_UPDATE: (userId) => `/users/${userId}`,
    // USERS_DETAIL: (userId) => `/users/${userId}`,
    // USERS_DELETE: (userId) => `/users/${userId}`,

    //scholarships
    SCHOLARSHIP_LIST: "/scholarships",
    SCHOLARSHIPS_COUNT: "/scholarships/count",
    SCHOLARSHIP_FIELDS: (scholarshipId) => `/scholarship/${scholarshipId}/fields`,
    SCHOLARSHIP_REQUIREMENTS: (scholarshipId) => `/scholarship/${scholarshipId}/requirement`,

    SCHOLARSHIPS_DETAIL: (scholarshipId) => `/scholarships/${scholarshipId}`,
    SCHOLARSHIPS_DELETE: (scholarshipId) => `/scholarships/${scholarshipId}`,
    SCHOLARSHIPS_APPLICANTS: (scholarshipId) => `/scholarships/${scholarshipId}/applicants`,
    SCHOLARSHIPS_FIELDS_LIST: `/fields`,
    SCHOLARSHIPS_FIELDS_ONLY: `/fields?where={"type":"field"}`,
    SCHOLARSHIPS_REQUIREMENTS_ONLY: `/fields?where={"type":"requirement"}`,

    SCHOLARSHIP_LIST_EMAIL: (email) => `/scholarships/{scholarshipId}/applicants/getuserbyemail?email=${email}`,
}