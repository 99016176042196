import { SIDE_NAV_LIGHT, DIR_LTR, NAV_TYPE_TOP } from 'constants/ThemeConstant';
import { env, isTenant, tenant, tenantId } from './EnvironmentConfig'

export const APP_NAME = env.APP_NAME;
export const API_BASE_URL = env.API_ENDPOINT_URL
export const B2B_BASE_URL = env.B2B_API_ENDPOINT_URL
export const TENANT_API_ENDPOINT_URL = env.TENANT_API_ENDPOINT_URL
export const SUPER_ADMIN_API_ENDPOINT_URL = env.SUPER_ADMIN_API_ENDPOINT_URL
export const FRONTEND_DOMAIN = env.FRONTEND_DOMAIN
export const TENANT = tenant;
export const IS_TENANT = isTenant;
export const TENANT_ID = tenantId;

export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';

export const TENANT_PREFIX_PATH = '/tenant';

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_TOP,
	topNavColor: '#fff',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR
};
