export const Subjects = {
    DASHBOARD: 'dashboard',
}

export const Action = {
    Manage: 'manage',
    Create: 'create',
    Read: 'read',
    Update: 'update',
    Delete: 'delete',
}
