import React, { useEffect, useState } from 'react'
import { Row, Col } from "antd";
import { useSelector } from 'react-redux';
import { APP_NAME } from 'configs/AppConfig';
import { Button, Form, Input, Divider, Alert, Select, Checkbox, InputNumber } from "antd";
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion"
import { UserService_ } from 'services/common/user.service';
import TextArea from 'antd/lib/input/TextArea';

const backgroundURL = '/img/others/img-17.jpg'
const backgroundStyle = {
	backgroundImage: `url(${backgroundURL})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover'
}

const ScholorshipDetail = props => {
	const theme = useSelector(state => state.theme.currentTheme)

	const [showMessage, setShowMessage] = useState(false);
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState();
	const [showInput, setShowInput] = useState(false);

	const [essayDetail, setEssayDetail] = useState('');
	const [form] = Form.useForm();
	const [essayForm] = Form.useForm();
	const { Option } = Select;
	const options = [
		{ value: 'Narrative', label: 'Narrative', description: 'This style of essay tells a story, often from the perspective of the author. It typically includes a clear beginning, middle, and end, and focuses on a specific event or experience.' },
		{ value: 'Descriptive', label: 'Descriptive', description: "A descriptive essay is all about creating a vivid picture in the reader's mind. It uses sensory details and descriptive language to convey a scene, person, or object." },
		{ value: 'Expository', label: 'Expository', description: "An expository essay presents information, explains an idea, or provides instructions. It's typically organized in a logical and straightforward manner, and often includes examples or evidence to support its points." },
	];

	useEffect(() => {
		console.log('essay details after api call ', essayDetail);
	}, [essayDetail])

	const handleCheckboxChange = (event) => {
		setShowInput(event.target.checked);
	};
	const getEssay = () => {
		console.log('loading on api call loading', loading);
		if (!loading) {

			form.validateFields().then(data => {
				setLoading(true);
				console.log('loading on api call loading', loading);
				const { showInputCheckbox, collegeName, areaOfStudy, cgpa, ...formData } = data;
				let userSpecifics = '';
				if (collegeName) {
					userSpecifics = `College Name :${collegeName}`
				}
				if (areaOfStudy) {
					userSpecifics += `, Area Of Study :${areaOfStudy}`
				}
				if (cgpa) {
					userSpecifics += `, CGPA :${cgpa}`
				}

				const apiData = { ...formData, wordCount: formData.wordCount.toString(), userSpecifics: userSpecifics };
				console.log('form data', apiData);
				UserService_.getEssay(apiData)
					.then((res) => {
						const message = res?.content;
						setEssayDetail(message);
						console.log('value after api call ', message);
					})
					.finally((_) => setLoading(false));
			})
		}
	}

	const clearData = () => {
		setLoading(false);
		console.log('set loading false after resubmit', loading);
		setEssayDetail(null)
	}


	return (
		<div className="auth-container">
			<div className={`h-100 ${theme === 'light' ? 'bg-white' : ''}`}>
				<Row justify="center" className="align-items-stretch h-100">
					<Col xs={20} sm={20} md={24} lg={16}>
						<div className="container d-flex flex-column justify-content-center h-100">
							<Row justify="center">
								<Col xs={24} sm={24} md={20} lg={12} xl={8}>
									<h1>Scholorship</h1>
									<p>First Page of Demo to get scholarship details</p>
									<div className="mt-4">
										<>
											<motion.div
												initial={{ opacity: 0, marginBottom: 0 }}
												animate={{
													opacity: showMessage ? 1 : 0,
													marginBottom: showMessage ? 20 : 0
												}}>
												<Alert type="error" showIcon message={message}></Alert>
											</motion.div>

											{
												essayDetail ?

													<Form form={form} layout="vertical" name="register-form" fields={[
														{
															name: ["essay"],
															value: essayDetail,
														},
													]}
														onFinish={() => {
															// submitEssay()
														}}>
														<Form.Item
															name="essay"
															label="Essay"
															rules={[{ required: true }]}
														//initialValue={essayDetail}
														>
															<TextArea prefix={<div className="text-primary" />} value={essayDetail} style={{ height: 100 }} />
														</Form.Item>
														<Form.Item>
															<Button type="primary" htmlType="submit" block loading={loading}>
																Submit Essay
															</Button>
														</Form.Item>
														<Form.Item>
															<Button type="dashed" htmlType="button" block onClick={() => clearData()}>
																Re Submit
															</Button>
														</Form.Item>
													</Form>

													:

													<Form form={form} layout="vertical" name="register-form" onFinish={() => {
														getEssay()
													}}>
														<Form.Item
															name="essayTitle"
															label="Essay Title"
															rules={[{ required: true }]}
														>
															<Input prefix={<div className="text-primary" />} />
														</Form.Item>
														<Form.Item
															name="style"
															label="Style"
															rules={[{ required: true, message: 'Please select essay style' }]}
														>
															<Select placeholder="Please select essay style">
																{options.map((option) => (
																	<Option key={option.value} value={option.value} title={option.description}>
																		{option.label}
																	</Option>))}
															</Select>
														</Form.Item>
														<Form.Item
															name="wordCount"
															label="Word Count not more than 500"
															rules={[{ required: true }]}
														>
															<InputNumber min={1} max={500} />
														</Form.Item>
														<Form.Item name="showInputCheckbox" valuePropName="checked">
															<Checkbox onChange={handleCheckboxChange}>Do you want to include User information to make more personalized essay</Checkbox>
														</Form.Item>
														{showInput && (
															<>
																<Form.Item name="collegeName" label="College/School Name">
																	<Input prefix={<div className="text-primary" />} />
																</Form.Item>
																<Form.Item name="areaOfStudy" label="Area Of Study">
																	<Input prefix={<div className="text-primary" />} />
																</Form.Item>

																<Form.Item name="cgpa" label="CGPA">
																	<InputNumber min={1} max={10} />
																</Form.Item>
															</>

														)}
														<Form.Item>
															<Button type="primary" htmlType="submit" block loading={loading}>
																Generate Essay
															</Button>
														</Form.Item>
													</Form>
											}
										</>
									</div>
								</Col>
							</Row>
						</div>
					</Col>
					<Col xs={0} sm={0} md={0} lg={8}>
						<div className="d-flex flex-column justify-content-between h-100 px-4" style={backgroundStyle}>
							<div className="text-right">
								<img src="/img/logo-white.png" alt="logo" />
							</div>
							<Row justify="center">
								<Col xs={0} sm={0} md={0} lg={20}>
									<img className="img-fluid mb-5" src="/img/others/img-19.png" alt="" />
									<h1 className="text-white">Welcome to ApplyScholar</h1>
									<p className="text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper nisl erat, vel convallis elit fermentum pellentesque.</p>
								</Col>
							</Row>
							<div className="d-flex justify-content-end pb-4">
								<div>
									<a className="text-white" href="/#" onClick={e => e.preventDefault()}>Term & Conditions</a>
									<span className="mx-2 text-white"> | </span>
									<a className="text-white" href="/#" onClick={e => e.preventDefault()}>Privacy & Policy</a>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default ScholorshipDetail
